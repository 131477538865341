import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from '../styles/404.styles';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import logoOKE from '../images/icons/logoOKE.svg';

const NotFoundPage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Layout>
            <SEO title="404: Not found" />
            <Box className={classes.root}>
                <Grid
                    container
                    direction={`${mdDown ? 'column' : 'row'}`}
                    justify="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item xs lg>
                        <Typography
                            paragraph
                            variant="h1"
                            gutterBottom
                            className={classes.text}
                        >
                            BŁĄD 404
                        </Typography>
                        <Typography
                            paragraph
                            variant="body1"
                            gutterBottom
                            className={classes.text}
                        >
                            Niestety strona o podanym adresie nie istnieje.{' '}
                            <br />
                            Jeżeli błąd będzie się powtarzał, prosimy powiadom
                            nas o tym.
                        </Typography>
                        <MuiLink href="/" underline="none">
                            <Typography
                                variant="body2"
                                className={classes.link}
                            >
                                Przejdź do strony głównej
                            </Typography>
                        </MuiLink>
                    </Grid>
                    <Grid item xs lg>
                        <Box className={classes.gif} />
                    </Grid>
                </Grid>
                <Box className={classes.footer}>
                    <Box className={classes.mobileFooter}>
                        OKE Poland Sp. z o.o.
                        <br />
                        ul. Heweliusza 11, 80-890 Gdańsk
                    </Box>
                    <Box className={classes.mobileFooter}>
                        tel. +48 58 321 72 46
                        <br />
                        www.oke.pl
                    </Box>
                    <MuiLink
                        href="https://www.oke.pl"
                        className={classes.linkLogo}
                    >
                        <img
                            src={logoOKE}
                            alt="Logo OKE"
                            className={classes.logoOke}
                        />
                    </MuiLink>
                </Box>
            </Box>
        </Layout>
    );
};

export default NotFoundPage;
